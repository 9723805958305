import React from 'react'
import styled from 'styled-components'
import { ContainerMain } from "../containers";
import { MdVpnKey, MdCreditCard } from "react-icons/md";

const PanelLabel = styled.h3`
  color: ${props => props.theme.theme.colors.blue};
  margin: 0px 0 8px 0;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  /* text-transform: uppercase; */
`;

const AdPanelBlue = styled(ContainerMain)`
  padding: 0px 12px;
  /* padding: 0px; */
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.theme.border.secondary};
  /* border: 1px solid ${(props) => props.theme.theme.colors.blue}; */
  /* background-color: ${(props) => props.theme.theme.bg.primary}; */
  background-color: ${(props) => props.theme.theme.colors.transparentBlue};
  /* box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  /* display: flex; */
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 12px;
  align-items: center;
  /* border-bottom: 2px solid ${props => props.theme.theme.colors.blue}; */
  color: ${props => props.theme.theme.colors.blue};


  @media (max-width: 500px) {
    border: none;
    border-radius: 0px;
  }

  p {
    font-family: 'Inter', sans-serif;
    color: ${props => props.theme.theme.text.primary};

    a{
      border-bottom: 2px solid ${props => props.theme.theme.colors.blue};

      &:hover {
        border-bottom: 2px solid ${props => props.theme.theme.colors.blue};
      }
    }

  }
  
  &:hover{
    cursor: pointer;
    border: 1px solid ${props => props.theme.theme.colors.blue};
    /* background-color: ${(props) => props.theme.theme.colors.transparentGreenHover}; */
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  }

  transition: box-shadow 0.1s ease-in-out;
  transition: border-color 0.1s ease-in-out;
`

const AdPanelPurple = styled(AdPanelBlue)`
  background-color: ${(props) => props.theme.theme.colors.transparentPurple};
  color: ${props => props.theme.theme.colors.purple};

  &:hover{
    border: 1px solid ${props => props.theme.theme.colors.purple};
  }
`

export const BlockFiCardAdPanel = () => {
  return(
    <a href="https://blockfi.mxuy67.net/c/2708620/1080104/10568" target="_blank">
      <AdPanelBlue>
        <MdCreditCard size="30px" />
        <p>
          <strong>BlockFi Rewards Visa Signature Credit Card.</strong> Earn an unlimited 1.5% back in bitcoin. Plus no annual fee and no foreign Tx fees.
        </p>
      </AdPanelBlue>
    </a>
  )
}

export const LedgerAdPanel = () => {
  return(
    <a href="https://shop.ledger.com/products/ledger-nano-s?r=24f4cff816e1" target="_blank">
      <AdPanelPurple>
      <MdVpnKey size="30px" />
        <p>
          <strong>Not your keys, not your coins.</strong> Secure your crypto assets with the Ledger Nano S,
          the top-rated hardware wallet in the crypto industry.
        </p>
      </AdPanelPurple>
    </a>
  )
}


