import React from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format';
import { MdLink } from "react-icons/md";

const AssetInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 0.5fr 1.5fr 1fr;
  margin: 48px 0 0 0;

  .crypto-icon{
    svg{
      margin: auto auto auto 0;
      width: 32px;
      height: 32px;
    }
  }


  @media (max-width: 700px) {
    margin: 24px 0 0 0;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 12px;
  }
`
const AssetInfoGridItem = styled.div`
  display: grid;

  h4 {
    margin: 0 0 0 0;
  }

  h5 {
    color: ${props => props.theme.theme.text.tertiary};
    font-size: 0.8rem;
    margin: 0 0 0 0;
    text-transform: uppercase;
  }

`

const SecondaryButton = styled.button`
  background-color: ${(props) => props.theme.theme.buttonFill.secondary};
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  /* color: ${(props) => props.theme.theme.colors.white}; */
  /* border: 1px solid ${(props) => props.theme.theme.colors.green}; */
  padding: 10px 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;

  svg {
    padding: 0 0 0 8px;
  }

  &:hover{
    cursor: pointer;
    /* background-color: ${(props) => props.theme.theme.colors.transparentGreenHover}; */
    /* box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1); */
  }
`

const AssetInfoHeader = ( {name, ticker, currentPrice, rank, marketCap, url, icon} ) => {
  return (
    <AssetInfoGrid>

      <AssetInfoGridItem className="crypto-icon">
        {icon}
      </AssetInfoGridItem>

      <AssetInfoGridItem>
        <h5>Asset</h5>
        <h4>{name}</h4>
      </AssetInfoGridItem>

      <AssetInfoGridItem>
        <h5>Ticker</h5>
        <h4>{ticker}</h4>
      </AssetInfoGridItem>

      <AssetInfoGridItem>
        <h5>Current Price</h5>
        <h4>
          <NumberFormat
            displayType={"text"}
            value={currentPrice.toFixed(2)}
            thousandSeparator
            prefix={`$`}
          />
        </h4>
      </AssetInfoGridItem>

      <AssetInfoGridItem>
        <h5>Rank</h5>
        <h4>#{rank}</h4>
      </AssetInfoGridItem>

      <AssetInfoGridItem>
        <h5>Market Cap</h5>
        <h4>
          <NumberFormat
            displayType={"text"}
            value={marketCap.toFixed(0)}
            thousandSeparator
            prefix={`$`}
          />
        </h4>
      </AssetInfoGridItem>

      <AssetInfoGridItem style={{justifyContent: 'left'}}>
        <a href={url} target="_blank">
          <SecondaryButton style>
            Website <MdLink size="20px" />
          </SecondaryButton>
        </a>
      </AssetInfoGridItem>
    </AssetInfoGrid>
  )
}

export default AssetInfoHeader
